/**
 * Template Name: MyResume
 * Template URL: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/
 * Updated: Mar 17 2024 with Bootstrap v5.3.3
 * Author: BootstrapMade.com
 * License: https://bootstrapmade.com/license/
 */

import Typed from 'typed.js';


import AOS from "aos";
import "aos/dist/aos.css";

import Isotope from "isotope-layout";

import 'boxicons/css/animations.css';
import 'boxicons/css/boxicons.css';

import 'glightbox/dist/css/glightbox.css';

import 'boxicons/css/transformations.css';
import GLightbox from "glightbox";
import Swiper from "swiper/bundle";

(function () {
    "use strict";

    /**
     * Scroll menu and Meta data function
     */
    const sections = document.querySelectorAll('section');
    const pageTitle = document.getElementById('page-title');
    const translationsDiv = document.getElementById('translations');
    const translations = {
        hero: document.getElementById('translations').dataset.hero + ' | Cultureweb',
        about: document.getElementById('translations').dataset.about + ' | Cultureweb',
        skills: document.getElementById('translations').dataset.skills + ' | Cultureweb',
        resume: document.getElementById('translations').dataset.resume + ' | Cultureweb',
        portfolio: document.getElementById('translations').dataset.portfolio + ' | Cultureweb',
        services: document.getElementById('translations').dataset.services + ' | Cultureweb',
        references: document.getElementById('translations').dataset.references + ' | Cultureweb',
        contact: document.getElementById('translations').dataset.contact + ' | Cultureweb',
    };
    const metaDescriptions = {
        hero: translationsDiv.dataset.metaHero,
        about: translationsDiv.dataset.metaAbout,
        skills: translationsDiv.dataset.metaSkills,
        resume: translationsDiv.dataset.metaResume,
        portfolio: translationsDiv.dataset.metaPortfolio,
        services: translationsDiv.dataset.metaServices,
        references: translationsDiv.dataset.metaReferences,
        contact: translationsDiv.dataset.metaContact
    };
    const sectionData = {
        hero: {
            title: translations.hero,
            description: metaDescriptions.hero
        },
        about: {
            title: translations.about,
            description: metaDescriptions.about
        },
        skills: {
            title: translations.skills,
            description: metaDescriptions.skills
        },
        resume: {
            title: translations.resume,
            description: metaDescriptions.resume
        },
        portfolio: {
            title: translations.portfolio,
            description: metaDescriptions.portfolio
        },
        services: {
            title: translations.services,
            description: metaDescriptions.services
        },
        references: {
            title: translations.references,
            description: metaDescriptions.references
        },
        contact: {
            title: translations.contact,
            description: metaDescriptions.contact
        }
    };
        function updateMetaTags(section) {
            if (sectionData[section]) {
                document.title = sectionData[section].title;
                pageTitle.innerText = sectionData[section].title;
                document.querySelector('meta[name="description"]').setAttribute('content', sectionData[section].description);
            }
        }
    window.addEventListener('scroll', function() {
        let currentSection = 'hero'; // Default section
        sections.forEach(section => {
            const sectionTop = section.offsetTop;
            if (window.scrollY >= sectionTop - 50) {
                currentSection = section.getAttribute('id');
            }
        });
        updateMetaTags(currentSection);
    });

    // Initial load
    window.addEventListener('DOMContentLoaded', function() {
        let currentSection = 'hero'; // Default section
        sections.forEach(section => {
            const sectionTop = section.offsetTop;
            if (window.scrollY >= sectionTop - 50) {
                currentSection = section.getAttribute('id');
            }
        });
        updateMetaTags(currentSection);
    });

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        let selectEl = select(el, all)
        if (selectEl) {
            if (all) {
                selectEl.forEach(e => e.addEventListener(type, listener))
            } else {
                selectEl.addEventListener(type, listener)
            }
        }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos,
            behavior: 'smooth'
        })
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function (e) {
        select('body').classList.toggle('mobile-nav-active')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
    })

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function (e) {
        if (select(this.hash)) {
            e.preventDefault()

            let body = select('body')
            if (body.classList.contains('mobile-nav-active')) {
                body.classList.remove('mobile-nav-active')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(this.hash)
        }
    }, true)

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
        if (window.location.hash) {
            if (select(window.location.hash)) {
                scrollto(window.location.hash)
            }
        }
    });

    /**
     * Preloader
     */
    let preloader = select('#preloader');
    if (preloader) {
        window.addEventListener('load', () => {
            preloader.remove()
        });
    }

    /**
     * Hero type effect
     */
    document.addEventListener('DOMContentLoaded', function () {
        const typed = select('.typed')
        if (typed) {
            let typed_strings = typed.getAttribute('data-typed-items')
            typed_strings = typed_strings.split(',')
            new Typed('.typed', {
                strings: typed_strings,
                loop: true,
                typeSpeed: 100,
                backSpeed: 50,
                backDelay: 2000
            });
        }
    });

    // /**
    //  * Skills animation
    //  */
    // document.addEventListener('DOMContentLoaded', function () {
    //     let skillsContent = select('.skills-content');
    //     if (skillsContent) {
    //         let progressBars = document.querySelectorAll('.progress .progress-bar');
    //
    //         let waypoint = new Waypoint({
    //             element: skillsContent,
    //             handler: function(direction) {
    //                 if (direction === 'down') {
    //                     progressBars.forEach(function(progressBar) {
    //                         progressBar.style.width = progressBar.getAttribute('aria-valuenow') + '%';
    //                     });
    //                 }
    //             },
    //             offset: '80%' // Trigger handler when 80% of element is in viewport
    //         });
    //     }
    //
    // });

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
        let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
            let portfolioIsotope = new Isotope(portfolioContainer, {
                itemSelector: '.portfolio-item'
            });

            let portfolioFilters = select('#portfolio-flters li', true);

            on('click', '#portfolio-flters li', function (e) {
                e.preventDefault();
                portfolioFilters.forEach(function (el) {
                    el.classList.remove('filter-active');
                });
                this.classList.add('filter-active');

                portfolioIsotope.arrange({
                    filter: this.getAttribute('data-filter')
                });
                portfolioIsotope.on('arrangeComplete', function () {
                    AOS.refresh()
                });
            }, true);
        }

    });

    /**
     * Initiate portfolio lightbox
     */
    document.addEventListener('DOMContentLoaded', function () {
        const portfolioLightbox = GLightbox({
            selector: '.portfolio-lightbox'
        });


        /**
         * Initiate portfolio details lightbox
         */
        const portfolioDetailsLightbox = GLightbox({
            selector: '.portfolio-details-lightbox',
            width: '90%',
            height: '90vh'
        });
    });

    /**
     * Portfolio details slider
     */
    document.addEventListener('DOMContentLoaded', function () {
        new Swiper('.portfolio-details-slider', {
            speed: 400,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });


        /**
         * Testimonials slider
         */
        new Swiper('.testimonials-slider', {
            speed: 600,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            }
        });
    });

    /**
     * Animation on scroll
     */
    window.addEventListener('load', () => {
        AOS.init({
            duration: 1000,
            easing: 'ease-in-out',
            once: true,
            mirror: false
        })
    });

    // /**
    //  * Initiate Pure Counter
    //  */
    // document.addEventListener('DOMContentLoaded', function () {
    // new PureCounter();
    // });

})()